import React from "react";
import '../Projectsstyle.css';
import 'animate.css';
import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';


function Projects() {
    return (
        <main>
     <div className="projects-container">
     <AnimationOnScroll animateIn="animate__fadeIn">
        <h1 className="projects-titel ">Projects<span className="dot">.</span></h1>
        </AnimationOnScroll>
        <p className="projects-p">Here you can find my most recent projects.</p>
        <br/>
        <br/>
        <br/>
        <div className="projects-card">
            <img src="https://camo.githubusercontent.com/36cc7c59c335a209cbf030029a509339f3e4d711f4a7e8584d7a827f96359f97/68747470733a2f2f692e6779617a6f2e636f6d2f64376164643834336239313535653264313636353532636135313735373365642e706e67" width="300px" height="200px"/>
           
           <div className="card-info">
             <h1>Crypto Price Tracker</h1>
             <p className="card-p">A Web-App that tracks the price of the top 50 Cryptocurrencies, the daily moves, the marketcap and  the volume of trading. </p>
             <p className="socials ">
     <a  href="https://github.com/vano000/CryptoTracker" target="_blank">
                <svg id="github" xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bibi-github" viewBox="0 0 16 16">
                    <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z"/>
                  </svg>
    </a>
    </p>
             </div>

           
        </div>

        <br/>
        <br/>
        <AnimationOnScroll animateIn="animate__fadeInLeftBig">
        <hr/>
        </AnimationOnScroll>
        <br/>
        <br/>

        <div className="projects-card">
            <img src="https://user-images.githubusercontent.com/41467529/181228510-3bcbd46b-d38f-4480-b75e-bdf75ee6ed4e.png" width="300px" height="200px"/>
           
           <div className="card-info">
             <h1>QR Code Generator</h1>
             <p className="card-p">A Web-App that generates a QR code based on your input. </p>
             <p className="socials ">
     <a  href="https://github.com/vano000/QR-Generator" target="_blank">
                <svg id="github" xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bibi-github" viewBox="0 0 16 16">
                    <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z"/>
                  </svg>
    </a>
    </p>
             </div>

             
        </div>
        <br/>
        <br/>
        <AnimationOnScroll animateIn="animate__fadeInRightBig">
        <hr/>
        </AnimationOnScroll>
        <br/>
        <br/>

        <div className="projects-card">
            <img src="https://camo.githubusercontent.com/6442f4f4df585155c5e8695b8185b9578a365a678abc0ab8137363546c0600eb/68747470733a2f2f692e6779617a6f2e636f6d2f61313137363636326661303234643963346334383836666530396664316137632e706e67" width="300px" height="200px"/>
           
           <div className="card-info">
             <h1>Football Standings</h1>
             <p className="card-p">A Web-App that tracks the season standings based on the league and season.  </p>
             <p className="socials ">
     <a  href="https://github.com/vano000/FootBall-Standings" target="_blank">
                <svg id="github" xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bibi-github" viewBox="0 0 16 16">
                    <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z"/>
                  </svg>
    </a>
    </p>
             </div>

             
        </div>


        <AnimationOnScroll animateIn="animate__fadeInDown">
        <hr className="herohr"/>
        </AnimationOnScroll>
     </div>
     
      </main>
    );
  }
  
  export default Projects;