import './App.css';
import Hero from './components/Hero';
import Projects from './components/Projects';
import Contact from './components/Contact';

function App() {
  return (
    <div className="App">
     <Hero />
     <Projects />
     <Contact />
    </div>
  );
}

export default App;
